import React from 'react';

import { AttendanceQRScreen, LoginPage, ShiftScreen, CoursesScreen, FormsScreen } from '@know/pages';
import { KNOWProvider, KNOWRoutes, KNOWDBContext } from '@know/ui';
import appJson from './app.config';

export default function App() {
  const hasAccessToModule = () => {
    return true;
  };

  return (
    <KNOWProvider isDark={true} hasAccessToModule={hasAccessToModule} enableNotifications={true}>
      <HomePage />
    </KNOWProvider>
  );
}

const HomePage = () => {
  const version = appJson.expo.version || '1.0.0'
  const { currentUser } = React.useContext(KNOWDBContext);

  return currentUser ? <>
      <CoursesScreen />
      <FormsScreen />
      <ShiftScreen />
      <AttendanceQRScreen />
      <KNOWRoutes version={version} />
    </> :
    <LoginPage />
};
